import { useEffect, useState, useRef } from "react";
import GroupPill from "./GroupPill";
import './ContentGroupsList.scss';
import { MOBILE_WIDTH } from "../../../utils/constants";

const MORE_BUTTON_WIDTH = 36; // Width of "..." button as a constant
const PILL_WIDTH_OFFSET = 60; // Offset to account for additional padding/margins
const LEEWAY = 5; // Extra space for leeway

const ContentGroupsList = ({ contentGroups, onRemove }) => {
	const containerRef = useRef(null);
	const dropdownRef = useRef(null);
	const toggleRef = useRef(null);

	const [visibleGroups, setVisibleGroups] = useState([]);
	const [overflowGroups, setOverflowGroups] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const [pillWidths, setPillWidths] = useState([]);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH);

	const adjustGroups = () => {
		if (isMobile) {
			// Mobile view logic: show only the first 3 pills, move the rest to overflow
			setVisibleGroups(contentGroups.slice(0, 3));
			setOverflowGroups(contentGroups.slice(3));
		} else {
			// Desktop view logic
			if (containerRef.current && pillWidths.length === contentGroups.length) {
				const containerWidth = containerRef.current.offsetWidth;
				let totalWidth = 0;
				const visible = [];
				const overflow = [];

				pillWidths.forEach((width, index) => {
					if (totalWidth + width + MORE_BUTTON_WIDTH + LEEWAY <= containerWidth) {
						visible.push(contentGroups[index]);
						totalWidth += width;
					} else {
						overflow.push(contentGroups[index]);
					}
				});

				setVisibleGroups(visible);
				setOverflowGroups(overflow);
			}
		}
	};

	useEffect(() => {
		// Update isMobile state on window resize
		const handleResize = () => setIsMobile(window.innerWidth <= MOBILE_WIDTH);
		window.addEventListener('resize', handleResize);

		// Initial adjustment and cleanup
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		// Measure each GroupPill�s width by rendering them off-screen first
		const widths = contentGroups.map(group => {
			const tempSpan = document.createElement("span");
			tempSpan.style.visibility = "hidden";
			tempSpan.style.position = "absolute";
			tempSpan.style.whiteSpace = "nowrap";
			tempSpan.textContent = group.Name;
			document.body.appendChild(tempSpan);
			const width = tempSpan.getBoundingClientRect().width + PILL_WIDTH_OFFSET;
			document.body.removeChild(tempSpan);
			return width;
		});
		setPillWidths(widths);
	}, [contentGroups]);

	useEffect(() => {
		adjustGroups();

		const observer = new ResizeObserver(adjustGroups);
		if (containerRef.current) {
			observer.observe(containerRef.current);
		}
		return () => {
			if (containerRef.current) {
				observer.unobserve(containerRef.current);
			}
		};
	}, [contentGroups, pillWidths, isMobile]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target) &&
				toggleRef.current &&
				!toggleRef.current.contains(event.target)
			) {
				setShowDropdown(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div ref={containerRef} className={`content-groups ${showDropdown ? 'dropdown-open' : ''}`}>
			{visibleGroups.map(group => (
				<GroupPill
					key={group.Id}
					id={group.Id}
					name={group.Name}
					onRemove={() => onRemove(group.Id)}
				/>
			))}
			{overflowGroups.length > 0 && (
				<>
					<span
						ref={toggleRef}
						className="more-groups"
						onMouseDown={() => setShowDropdown(prev => !prev)}
					>
						...
					</span>
					<div
						ref={dropdownRef}
						className={`overflow-groups-dropdown ${showDropdown ? 'show' : ''}`}
					>
						<div className="overflow-groups-dropdown-groups">
							{overflowGroups.map(group => (
								<GroupPill
									key={group.Id}
									id={group.Id}
									name={group.Name}
									onRemove={() => onRemove(group.Id)}
								/>
							))}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default ContentGroupsList;
