import { Button } from "components/Button/Button";
import GroupPill from "./components/GroupPill";
import DataTable from "components/DataTable/DataTable";
import DropdownButton from "components/DropdownButton/DropdownButton";
import MessageModal from "components/Modal/MessageModal";
import { ModalButtons } from "components/Modal/ModalButtons";
import { useModal } from "components/Modal/context/ModalContext";
import Pod from "components/Pod/Pod";
import "components/Toast.scss";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DataFetcher } from "components/DataFetcher";
import { getFetchOptions } from "utils/fetch";
import { ActionType } from "./ActionType";
import UserEditor from "./UserEditor/UserEditor";
import "./UserManagement.scss";
import ConfirmationContent from "./components/ConfirmationContent";
import DisplaySeats from "./components/DisplaySeats";
import ContentGroupsList from "./components/ContentGroupsList";

const UserManagement = () => {
	const navigate = useNavigate();
	const [fetchData, setFetchData] = useState({ url: "/api/v3/CompanyUser" });
	const initialRequest = useRef(true);
	const [userData, setUserData] = useState(null);
	const maxSeats = userData?.MaxSeats ?? 0;
	const usedSeats = userData?.UsedSeats ?? 0;
	const hasDeviceTraining = userData?.HasDeviceTraining ?? false;
	const companyUsers = useMemo(() => { return userData?.CompanyUsers ?? []; }, [userData]);
	const companyId = userData?.CompanyId ?? 0;
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [hoveredDeviceGroupId, setHoveredDeviceGroupId] = useState(null);
	const { openModal, closeModal } = useModal();
	const toast = useRef(null);
	const { state } = useLocation();  // Added useLocation hook to capture state
	const [confirmation, setConfirmation] = useState({ action: null });
	const confirmResend = useRef(false);
	const [openEditor, setOpenEditor] = useState(false);
	const newCustomDeviceGroupId = useRef(-1);
	const editor = useRef(false);

	const setFetchRequestData = useCallback((url, options = null) => {
		setFetchData({ url: url, options: options });
	}, []);
	
	const handleConfirmationCancellation = () => {
		setConfirmation({ action: null });
	};

	/**
	 * @note Fetch request different success return data:
	 *   1) GET /api/v3/CompanyUser - List<CompanyUserManagementDto>
	 *   2) DELETE /api/v3/CompanyUser - BulkCompanyUserDeleteOutputData
	 *   3) POST /api/v3/ResendCompleteRegistration - TRUE
	 *   4) PUT /api/v3/UnassignUserContentGroups - TRUE
	 *   5) PUT /api/v3/UnassignUserDeviceGroup - TRUE
	 */
	const handleData = useCallback((data) => {
		if (data) {
			if (initialRequest.current) {
				for (let user of data.CompanyUsers) {
					user.ContentGroups.sort((a, b) => { return a.Position - b.Position });
				}
				setUserData(data);
				initialRequest.current = false;
			}
			else if (data.SuccessfulDeletes) {
				handleConfirmationCancellation();
				setFetchRequestData("/api/v3/CompanyUser");
				initialRequest.current = true;
			}
			else if (typeof (data) === "boolean") {
				if (confirmResend.current) {
					setSelectedUsers((prev) => {
						const message = (prev.length === 1)
							? "Registration email sent to " + prev[0].Username
							: `Registration email sent to ${prev.length} users`;
						toast.current.show({ severity: "info", summary: "Info", detail: message });
						confirmResend.current = false;
						handleConfirmationCancellation();
						return [];
					});
				}
				else {
					setFetchRequestData("/api/v3/CompanyUser");
					initialRequest.current = true;
				}
			}
		}
	}, [setFetchRequestData]);
	

	const handleConfirmDelete = (yes, users) => {
		if (yes) {
			const selectedIds = users.map(user => user.Id);
			const options = getFetchOptions({
				method: "DELETE",
				body: selectedIds
			});
			setFetchRequestData("/api/v3/CompanyUser", options);
		}
		else {
			handleConfirmationCancellation();
		}
		setSelectedUsers([]);
	};

	const handleConfirmResend = (yes, users) => {
		if (yes) {
			const selectedUsernames = users.map(user => user.Username);
			const options = getFetchOptions({
				method: "POST",
				body: selectedUsernames
			});
			confirmResend.current = true;
			setFetchRequestData("/api/v3/ResendCompleteRegistration", options);
		}
		else {
			handleConfirmationCancellation();
			setSelectedUsers([]);
		}
	};

	const closeModalAndRefresh = useCallback(() => {
		closeModal();
		navigate(0);
	}, [closeModal, navigate]);
	
	const handleOpenAddModal =  useCallback((usersToEdit) => {
		openModal(
			"Add User",
			<UserEditor
				edit={false}
				companyId={companyId}
				users={usersToEdit}
				newCustomDeviceGroupId={newCustomDeviceGroupId.current}
				toast={toast.current}
				onClose={closeModalAndRefresh}
				allUsers={companyUsers}
				updateCompanyUsers={updateCompanyUsers}
			/>,
			ModalButtons.None
		);
	}, [openModal, companyId, closeModalAndRefresh, companyUsers]);

	const handleOpenEditModal = useCallback((clickedUser) => {
		const usersToEdit = selectedUsers.length > 0 ? [...selectedUsers] : [clickedUser];
		const editModalTitle = usersToEdit.length > 1 ? `Edit ${usersToEdit.length} Users` : "Edit User";
		openModal(
			editModalTitle,
			<UserEditor
				edit={true}
				companyId={companyId}
				users={usersToEdit}
				newCustomDeviceGroupId={newCustomDeviceGroupId.current}
				toast={toast.current}
				onClose={closeModalAndRefresh}
				allUsers={companyUsers}
				updateCompanyUsers={updateCompanyUsers}
			/>,
			ModalButtons.None
		);
		setSelectedUsers([]);
	}, [selectedUsers, openModal, companyId, closeModalAndRefresh, companyUsers]);
	
	const updateCompanyUsers = (updatedUsers) => {
		setUserData((prevData) => ({
			...prevData,
			CompanyUsers: updatedUsers,
		}));
	};
	
	// Automatically open modal if there are remembered selected users
	useEffect(() => {
		if (state && companyUsers.length > 0) {
			let rememberedSelectedUsers = state?.selectedUsers || [];  // Get remembered users from location state
			
			const updatedData = state?.updatedData;
			if (updatedData) {
				let usersToEdit = [];
				if (rememberedSelectedUsers.length > 0) {
					usersToEdit = companyUsers.filter((curr) => {
						return rememberedSelectedUsers.find(x => x.Id === curr.Id);
					});
				}
				if (!state?.edit) {
					usersToEdit[0] = {
						Id: -1,
						RoleId: updatedData.roleId
					};
				}
				usersToEdit[0] = {
					...usersToEdit[0],
					FirstName: updatedData.firstName,
					LastName: updatedData.lastName,
					Username: updatedData.username,
					ContentGroups: updatedData.contentGroups,
					DeviceGroup: updatedData.deviceGroup,
					CustomDeviceGroup: updatedData.customDeviceGroup
				};
				editor.current = state?.edit;
				newCustomDeviceGroupId.current = state?.newCustomDeviceGroupId
				setSelectedUsers(usersToEdit);
				setOpenEditor(true);
				navigate(window.location.pathname, { replace: true }); // Force removal of state to prevent an infinite loop
			}
		}
	}, [state, companyUsers, navigate]);

	useEffect(() => {
		if (openEditor && selectedUsers.length > 0) {
			if (editor.current) {
				handleOpenEditModal(null);
			}
			else {
				handleOpenAddModal(selectedUsers);
				setSelectedUsers([]);
			}
			setOpenEditor(false);
			newCustomDeviceGroupId.current = -1;
		}
	}, [selectedUsers, openEditor, handleOpenEditModal, handleOpenAddModal]);

	const toggleSelectAll = (checked) => {
		setSelectAll(checked);
		setSelectedUsers(checked ? companyUsers : []);
	};

	const handleSelectChange = (selected, user) => {
		if (selected) {
			setSelectedUsers(prevSelected => [...prevSelected, user]);
		}
		else {
			setSelectedUsers(prevSelected => prevSelected.filter(u => u.Id !== user.Id));
		}
	};

	const handleUnassignContentGroup = (groupId, rowData) => {
		const updatedUser = {
			Id: rowData.Id,
			CompanyId: rowData.CompanyId,
			Username: rowData.Username,
			ContentGroups: rowData.ContentGroups.filter(group => group.Id === groupId),
		};
		let unassignableUsers;
		if (selectedUsers.length === 0) {
			unassignableUsers = [updatedUser];
		}
		else {
			const updatedSelectedUsers = selectedUsers.map(user => {
				if (user.Id === rowData.Id) {
					return updatedUser;
				}
				return user;
			});
			unassignableUsers = updatedSelectedUsers;
		}
		const options = getFetchOptions({
			method: "PUT",
			body: unassignableUsers
		});
		setFetchRequestData("/api/v3/UnassignUserContentGroups", options);
		setSelectedUsers([]);
	};

	const handleUnassignDeviceGroup = (groupId, rowData) => {
		let unassignableUsers;
		if (selectedUsers.length === 0) {
			unassignableUsers = [{
				Id: rowData.Id,
				GroupId: groupId,
				Custom: !!rowData.CustomDeviceGroup
			}];
		}
		else {
			unassignableUsers = selectedUsers.map(user => {
				return {
					Id: user.Id,
					GroupId: groupId,
					Custom: !!rowData.CustomDeviceGroup
				}
			});
		}
		const options = getFetchOptions({
			method: "PUT",
			body: unassignableUsers
		});
		setFetchRequestData("/api/v3/UnassignUserDeviceGroup", options);
		setSelectedUsers([]);
	};

	const buildUserListForAction = (clickedUser) => {
		let finalUserList = selectedUsers.length > 0 ? [...selectedUsers] : [];
		if (!finalUserList.some(user => user.Id === clickedUser.Id)) {
			finalUserList.push(clickedUser);
		}
		return finalUserList;
	};

	const deleteUser = (clickedUser) => {
		const userList = buildUserListForAction(clickedUser);
		setSelectedUsers(userList);
		setConfirmation({ action: ActionType.Delete, items: userList, handleConfirm: handleConfirmDelete });
	};

	const resendRegistrationEmail = (clickedUser) => {
		const userList = buildUserListForAction(clickedUser);
		setSelectedUsers(userList);
		setConfirmation({ action: ActionType.Resend, items: userList, handleConfirm: handleConfirmResend });
	};

	const getButtonActionMenu = (rowData) => {
		let menu = [];
		if (selectedUsers.length > 0) {
			menu.push({ label: `${selectedUsers.length} ${selectedUsers.length === 1 ? "user" : "users"} selected` });
		}
		menu.push({ label: 'Edit', action: () => handleOpenEditModal(rowData) });
		if (selectedUsers.length <= 1) {
			menu.push({ label: "Resend Registration Email", action: () => resendRegistrationEmail(rowData), disabled: rowData.LastLogin });
			menu.push({ label: "Delete User", action: () => deleteUser(rowData), disabled: rowData.IsSelf });
		}
		else {
			const isSelfSelected = selectedUsers.some(user => user.IsSelf);
			const anyLoggedIn = selectedUsers.some(user => user.LastLogin);
			menu.push({ label: "Resend Registration Email", action: () => true, disabled: anyLoggedIn });
			menu.push({ label: `Delete ${selectedUsers.length} User${selectedUsers.length > 1 ? "s" : ""}`, action: () => true, disabled: isSelfSelected });
		}
		return menu;
	};

	const selectTemplate = (rowData) => (
		<Checkbox
			onChange={(e) => handleSelectChange(e.checked, rowData)}
			checked={selectedUsers.some(user => user.Id === rowData.Id)}
		/>
	);

	const deviceGroupTemplate = (rowData) => {
		if (!rowData.DeviceGroup && !rowData.CustomDeviceGroup) {
			return <span>No devices assigned</span>;
		}
		
		const isRowSelected = selectedUsers.some(user => user.Id === rowData.Id);
		const disableGroups = selectedUsers.length > 0 && !isRowSelected;
		const groupId = rowData.CustomDeviceGroup?.Id ?? rowData.DeviceGroup?.Id;
		return (
			<GroupPill
				key={rowData.Id}
				id={groupId}
				name={rowData.CustomDeviceGroup?.DeviceGroupName ?? rowData.DeviceGroup?.Name}
				onRemove={() => handleUnassignDeviceGroup(groupId, rowData)}
				disabled={disableGroups}
				hovered={isRowSelected && hoveredDeviceGroupId === groupId && selectedUsers.length > 0}
				onMouseEnter={() => isRowSelected && setHoveredDeviceGroupId(groupId)}
				onMouseLeave={() => isRowSelected && setHoveredDeviceGroupId(null)}
			/>
		);
	};

	const contentGroupsTemplate = (rowData) => (
		<ContentGroupsList
			contentGroups={rowData.ContentGroups}
			onRemove={(groupId) => handleUnassignContentGroup(groupId, rowData)}
		/>
	);


	const editTemplate = (rowData) => {
		const isDisabled = selectedUsers.length > 0 && !selectedUsers.some(user => user.Id === rowData.Id);
		return (
			<DropdownButton text={"Edit"} primaryAction={() => handleOpenEditModal(rowData)} buttonMenu={getButtonActionMenu(rowData)} disabled={isDisabled} />
		);
	};
	
	const columns = [
		{
			field: 'FullName',
			headerName: 'Name',
			valueGetter: (data) => { return data.FullName; },
			sortable: true
		},
		{
			field: 'Username',
			headerName: 'Email',
			valueGetter: (data) => { return data.Username; },
			sortable: true
		},
		...(hasDeviceTraining ? [{
			field: 'DeviceGroup',
			headerName: 'Device Group',
			valueGetter: deviceGroupTemplate,
			sortable: false
		}] : []),
		{
			field: 'ContentGroups',
			headerName: 'Content Groups',
			valueGetter: contentGroupsTemplate,
			sortable: false,
			style: { width: '40%' }
		},
		{
			headerName: 'Actions',
			valueGetter: editTemplate,
			style: { minWidth: '140px' },
			sortable: false,
			removeHeaderFromMobile: true
		}
	];

	const rowClassName = (rowData) => {
		return selectedUsers.some(user => user.Id === rowData.Id) ? 'active' : '';
	};

	const render = () => {
		return (
			<>
				{(confirmation.action !== null) &&
					<MessageModal
						modalTitle="Are you sure?"
						modalContent={<ConfirmationContent {...confirmation} />}
						modalButtons={ModalButtons.Custom}
						closeModal={handleConfirmationCancellation}
					/>
				}
				<DataFetcher fetchData={fetchData} setData={handleData} />
				<Pod title="User Management">
					{(maxSeats <= 999) && <DisplaySeats maxSeats={maxSeats} usedSeats={usedSeats} />}
					<div className="datatable-pod">
						<div className="datatable-pod-buttons">
							<Button text="Add User" onClick={() => handleOpenAddModal([])} />
						</div>
						<DataTable
							rows={companyUsers}
							columns={columns}
							rowsPerPage={10}
							rowClassName={rowClassName}
							useCardView={true} // Enable card view for mobile
						/>
						<div className="datatable-pod-buttons">
							<Button text="Add User" onClick={() => handleOpenAddModal([])} />
						</div>
					</div>
				</Pod>
				<Toast ref={toast} />
			</>
		);
	};
	
	return (
		<div className="grid-full gutters">{render()}</div>
	);
};

export default UserManagement;
