import { Button, ButtonType } from "components/Button/Button";
import { DataFetcher } from "components/DataFetcher";
import "components/Modal/Modal.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFetchOptions } from "utils/fetch";
import { isEmailValid, isNameValid } from "utils/inputValidation";
import { ActionType } from "../ActionType";
import ConfirmationContent from "../components/ConfirmationContent";
import ActionButtons from "./ActionButtons";
import "./UserEditor.scss";
import UserForm from "./UserForm";

const UserEditor = ({ edit, companyId, users = [], newCustomDeviceGroupId, toast, onClose, allUsers = [], updateCompanyUsers}) => {
	const navigate = useNavigate();
	const [prerequisites, setPrerequisites] = useState(null);
	const [fetchData, setFetchData] = useState({ url: `/api/CompanyUser/CreationPrerequisites/${companyId}/-99` });
	const [fetchError, setFetchError] = useState(false);
	
	const INITIAL_DATA = {
		Id: -1,
		firstName: "",
		lastName: "",
		username: "",
		roleId: -1,
		contentGroups: [],
		deviceGroup: null,
		customDeviceGroup: null
	};
	const [formData, setFormData] = useState(INITIAL_DATA);
	const contentGroupCount = useRef(0);
	const deviceGroupCount = useRef(0);
	const [canSave, setCanSave] = useState(false); // Track if Save button should be enabled
	const [confirmation, setConfirmation] = useState({ action: null });
	const confirmResend = useRef(false);
	const confirmGroupDeletion = useRef(false);
	const [deletedGroup, setDeletedGroup] = useState({});
	
	const setFetchRequestData = useCallback((url, options = null) => {
		setFetchData({ url: url, options: options });
	}, []);

	const rebuildDeviceGroupsData = useCallback((prerequisites, deletedGroup) => {
		if (edit) {
			if (users.length === 1) {
				const user = users[0];
				setFormData((prevData) => ({
					...prevData,
					deviceGroup: user.CustomDeviceGroup?.Id === deletedGroup.Id ? null : user.DeviceGroup || null,
					customDeviceGroup: user.CustomDeviceGroup?.Id === deletedGroup.Id ? null : user.CustomDeviceGroup
				}));
			}
			else if (users.length > 1) {
				const uniqueDeviceGroups = users
					.map(user => user.DeviceGroups?.[0]?.Id)
					.every(id => id !== undefined && id === users[0]?.DeviceGroups?.[0]?.Id);

				const uniqueCustomDeviceGroups = (newCustomDeviceGroupId !== -1)
					? prerequisites.CustomDeviceGroupData.find(x => x.Id === newCustomDeviceGroupId)
					: users
						.map(user => user.CustomDeviceGroup?.Id)
						.every(id => id !== undefined && id === users[0]?.CustomDeviceGroup?.Id);

				setFormData((prev) => {
					return {
						...prev,
						deviceGroup: uniqueDeviceGroups ? users[0].DeviceGroups?.[0] : null, // Handle regular device group
						customDeviceGroup: uniqueCustomDeviceGroups ? users[0].CustomDeviceGroup : null // Handle custom device group
					}
				});
			}
		}
	}, [edit, users, newCustomDeviceGroupId]);

	const buildFormData = useCallback((prerequisiteData) => {
		if (edit) {
			contentGroupCount.current = users.reduce((acc, user) => {
				user.ContentGroups.forEach(group => {
					acc[group.Id] = (acc[group.Id] || 0) + 1;
				});
				return acc;
			}, {});

			if (users.length === 1) {
				const user = users[0];
				setFormData({
					Id: user.Id,
					firstName: user.FirstName,
					lastName: user.LastName || '',
					username: user.Username,
					roleId: user.RoleId || -1,
					contentGroups: user.ContentGroups || [],
					deviceGroup: user.DeviceGroup || null,
					customDeviceGroup: (newCustomDeviceGroupId !== -1)
						? prerequisiteData.CustomDeviceGroupData.find(x => x.Id === newCustomDeviceGroupId)
						: user.CustomDeviceGroup || null
				});
			}
			else if (users.length > 1) {
				// Roles.
				const userRoles = users.map(user => user.RoleId);
				const uniqueRole = userRoles.every((value, i, array) => value === array[0]);
				const formDataRole = (uniqueRole) ? userRoles[0] : -1;

				// Content Groups.
				const formDataContentGroups = prerequisiteData.ContentGroupData.map(group => {
					const userCount = contentGroupCount.current[group.Id] || 0;
					if (userCount === 0) {
						return null; // Not selected
					}
					return { ...group, mixed: userCount !== users.length };
				}).filter(Boolean);

				// Device Group.
				const uniqueDeviceGroups = users
					.map(user => user.DeviceGroups?.[0]?.Id)
					.every(id => id !== undefined && id === users[0]?.DeviceGroups?.[0]?.Id);

				const uniqueCustomDeviceGroups = (newCustomDeviceGroupId !== -1)
					? prerequisiteData.CustomDeviceGroupData.find(x => x.Id === newCustomDeviceGroupId)
					: users
						.map(user => user.CustomDeviceGroup?.Id)
						.every(id => id !== undefined && id === users[0]?.CustomDeviceGroup?.Id);

				setFormData((prev) => {
					return {
						...prev,
						roleId: formDataRole,
						contentGroups: formDataContentGroups,
						deviceGroup: uniqueDeviceGroups ? users[0].DeviceGroups?.[0] : null, // Handle regular device group
						customDeviceGroup: uniqueCustomDeviceGroups ? users[0].CustomDeviceGroup : null // Handle custom device group
					}
				});
			}
		}
		else if (users.length === 1) {
			// Add User returning from Device Management.
			const user = users[0];
			setFormData({
				firstName: user.FirstName,
				lastName: user.LastName || '',
				username: user.Username,
				roleId: user.RoleId || -1,
				contentGroups: user.ContentGroups || [],
				deviceGroup: user.DeviceGroup || null,
				customDeviceGroup: (newCustomDeviceGroupId !== -1)
					? prerequisiteData.CustomDeviceGroupData.find(x => x.Id === newCustomDeviceGroupId)
					: user.CustomDeviceGroup || null
			});
		}
		else {
			// Add User (new).
			setFormData((prev) => {
				return {
					...prev,
					contentGroups: [...prerequisiteData.ContentGroupData],
					deviceGroup: prerequisiteData.DeviceGroupData[0] || null,
					roleId: prerequisiteData.RoleData[0].Id
				}
			});
		}
	}, [edit, users, newCustomDeviceGroupId]);

	/**
	 * @note Fetch request different success return data:
	 *   1) GET /api/CompanyUser/CreationPrerequisites/{companyId}/{userId} - CompanyUserCreationPrerequisiteDto
	 *   2) PUT /api/CompanyUser - {Id, Resent}
	 *   3) PUT /api/CompanyUser - BulkUpsertCompanyUserOutputData
	 *   4) POST /api/v3/ResendCompleteRegistration - TRUE
	 *   5) DELETE /api/v3/CompanyUser - BulkCompanyUserDeleteOutputData
	 *   6) DELETE /api/v3/DeviceGroup - NoContent 204
	 */
	const handleData = useCallback((data) => {
		if (data) {
			if (data.ContentGroupData) {
				data.ContentGroupData.sort((a, b) => { return a.Position - b.Position });
				setPrerequisites((prev) => {
					buildFormData(data);
					return data;
				});
				deviceGroupCount.current = users.reduce((acc, user) => {
					const deviceGroup = user.DeviceGroups?.[0]?.Id;
					if (deviceGroup) {
						acc[deviceGroup] = (acc[deviceGroup] || 0) + 1;
					}
					return acc;
				}, {});
			}
			else if (typeof (data) === "boolean" && confirmResend.current) {
				const message = (users.length === 1)
					? "Registration email sent to " + users[0].Username
					: `Registration email sent to ${users.length} users`;
				toast.show({ severity: "info", summary: "Info", detail: message });
				setConfirmation({ action: null });
				confirmResend.current = false;
			}
			else if (data.Status === 204) {
				if (!confirmGroupDeletion.current) {
					confirmGroupDeletion.current = true;
					setPrerequisites((prev) => ({
						...prev,
						CustomDeviceGroupData: prev.CustomDeviceGroupData.filter(
							(group) => group.Id !== deletedGroup.Id // Exclude the deleted group
						)
					}));
					rebuildDeviceGroupsData(prerequisites, deletedGroup);
					setConfirmation({ action: null });
					let updatedUsers = [...allUsers];
					updatedUsers = updatedUsers.map(user => ({
						...user,
						DeviceGroup: user.CustomDeviceGroup?.Id === deletedGroup.Id ? null : user.DeviceGroup || null,
						CustomDeviceGroup: user.CustomDeviceGroup?.Id === deletedGroup.Id ? null : user.CustomDeviceGroup
					}));
					updateCompanyUsers(updatedUsers);
					setDeletedGroup({});
				}
			}
			else if (onClose) { // We are not interested in the results of any PUT/DELETE...
				onClose();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onClose, toast, deletedGroup.Id]); // Adding "buildFormData" or "users" causes an infinite loop

	const handleError = useCallback((error) => {
		if (error.Status === 400 && error.Message === "ERR_USER_ALREADY_EXISTS") {
			setConfirmation({ action: null });
			setFetchError(true);
		}
	}, []);

	// Validation Effect for Save button enablement.
	useEffect(() => {
		if (formData) {
			const { firstName, lastName, username, roleId, contentGroups, deviceGroup, customDeviceGroup } = formData;
			let canSave = (isNameValid(firstName.trim(), false) && isNameValid(lastName.trim()) && roleId > 0);
			if (canSave) {
				if (edit) {
					const contentGroupsChanged = (a, b) => {
						return (a.length !== b.length || !a.map(x => x.Id).every(y => b.map(x => x.Id).includes(y)));
					};

					const deviceGroupChanged = (aCustom, aStandard, bCustom, bStandard) => {
						return (aCustom !== bCustom || ((!aCustom || !bCustom) && aStandard !== bStandard));
					};

					if (users.length === 1) {
						const user = users[0];
						canSave &= (
							(user.FirstName.trim() !== firstName.trim()) ||
							(user.LastName.trim() !== lastName.trim()) ||
							(user.RoleId !== Number(roleId)) ||
							(contentGroupsChanged(user.ContentGroups, contentGroups)) ||
							(deviceGroupChanged(user.CustomDeviceGroup?.Id, user.DeviceGroup?.Id, customDeviceGroup?.Id, deviceGroup?.Id))
						);
					}
				}
				else {
					canSave &= isEmailValid(username.trim());
				}
			}
			setCanSave(Boolean(canSave));
		}
	}, [formData, edit, users]);

	const confirmDeleteUser = () => setConfirmation({ action: ActionType.Delete, items: users });
	const confirmResendRegistrationEmail = () => setConfirmation({ action: ActionType.Resend, items: users });
	const confirmSubmit = () => {
		setConfirmation(edit
			? { action: ActionType.Update, items: users.length > 1 ? users : [formData] }
			: { action: ActionType.Add, items: [formData] }
		);
	};
	const confirmDeleteGroup = (group, usersWithGroup) => setConfirmation({ action: ActionType.DeleteGroup, items: usersWithGroup, deviceGroup: group });

	const deleteData = {
		action: confirmDeleteUser,
		disabled: !users || users.some(user => user.IsSelf)
	};

	const resendData = {
		action: confirmResendRegistrationEmail,
		disabled: !users || users.some(user => user.LastLogin)
	};

	const handleDeviceIconClick = (selectedGroup = null, custom = false) => {
		const state = {
			edit,
			selectedGroup,
			selectedUsers: users,
			updatedData: formData,
			deviceGroupOptions: prerequisites?.CustomDeviceGroupData || [],
			custom
		};
		navigate("/deviceselection", { state: state });

		onClose?.(); // Optional chaining to call onClose if it's defined
	};

	const handleDeleteIconClick = (selectedGroup = null) => {
		setDeletedGroup(selectedGroup);
		const usersWithGroup = allUsers.filter(user => user.CustomDeviceGroup?.Id === selectedGroup.Id);
		confirmDeleteGroup(selectedGroup, usersWithGroup);
	};
	
	const handleConfirmDelete = () => {
		const selectedIds = users.map(user => user.Id);
		const options = getFetchOptions({
			method: "DELETE",
			body: selectedIds
		});
		setFetchRequestData("/api/v3/CompanyUser", options);
	};

	const handleConfirmResend = () => {
		const selectedUsernames = users.map(user => user.Username);
		const options = getFetchOptions({
			method: "POST",
			body: selectedUsernames
		});
		setFetchRequestData("/api/v3/ResendCompleteRegistration", options);
	};

	const handleConfirmAdd = () => {
		const options = getFetchOptions({
			method: "PUT",
			body: {
				SingleUser: {
					Id: -1,
					CompanyId: companyId,
					FirstName: formData.firstName.trim(),
					LastName: formData.lastName.trim(),
					Username: formData.username.trim(),
					RoleId: formData.roleId,
					CreatedOnRegistration: false,
					Active: true,
					ContentGroups: formData.contentGroups,
					DeviceGroup: formData.deviceGroup,
					CustomDeviceGroup: formData.customDeviceGroup
				}
			}
		});
		setFetchRequestData("/api/CompanyUser", options);
	};

	const handleConfirmUpdate = () => {
		let updatedUsers = [...users];
		updatedUsers = updatedUsers.map(user => {
			let updatedContentGroups = user.ContentGroups.filter(group =>
				!prerequisites?.ContentGroupData.some(g => g.Id === group.Id) || formData.contentGroups.some(fg => fg.Id === group.Id)
			);
			formData.contentGroups.forEach(group => {
				if (group.Name && !user.ContentGroups.some(g => g.Id === group.Id)) {
					updatedContentGroups.push(group);
				}
			});

			return {
				Id: user.Id,
				CompanyId: companyId,
				FirstName: users.length > 1 ? user.FirstName : formData.firstName.trim(),
				LastName: users.length > 1 ? user.LastName : formData.lastName.trim(),
				Username: users.length > 1 ? user.Username : formData.username.trim(),
				RoleId: users.length > 1 ? user.RoleId : formData.roleId,
				Active: true,
				ContentGroups: updatedContentGroups,
				DeviceGroup: formData.deviceGroup,
				CustomDeviceGroup: formData.customDeviceGroup
			};
		});
		const options = getFetchOptions({
			method: "PUT",
			body: {
				Users: updatedUsers
			} 
		});
		setFetchRequestData("/api/CompanyUser", options);
	};

	const handleConfirmDeleteGroup = () => {
		const options = getFetchOptions({
			method: "DELETE",
			body: deletedGroup.Id
		});
		setFetchRequestData("/api/v3/DeviceGroup", options);
	};

	const handleConfirm = (yes) => {
		if (yes) {
			switch (confirmation.action) {
				case ActionType.Delete:
					handleConfirmDelete();
					break;
				case ActionType.Resend:
					handleConfirmResend();
					break;
				case ActionType.Add:
					handleConfirmAdd();
					break;
				case ActionType.Update:
					handleConfirmUpdate();
					break;
				case ActionType.DeleteGroup:
					confirmGroupDeletion.current = false;
					handleConfirmDeleteGroup();
					break;
				default:
					break;
			}
		}
		else {
			setConfirmation({ action: null });
		}
	};

	const UserEditorModal = (
		<>
			<div className="user-management-modal">
				{prerequisites && formData && (<UserForm
					users={users}
					edit={edit}
					prerequisites={prerequisites}
					formData={formData}
					setFormData={setFormData}
					contentGroupOptions={prerequisites?.ContentGroupData || []}
					deviceGroupOptions={prerequisites?.DeviceGroupData || []}
					deviceGroupUserCount={deviceGroupCount.current}
					customDeviceGroupOptions={prerequisites?.CustomDeviceGroupData || []}
					handleDeviceIconClick={handleDeviceIconClick}
					handleDeleteIconClick={handleDeleteIconClick}
				/>)}
			</div>
			<ActionButtons
				edit={edit}
				users={users}
				deleteData={deleteData}
				resendData={resendData}
				handleSubmitClick={confirmSubmit}
				canSave={canSave}
			/>
		</>
	);

	const FetchErrorContent = (
		<div className="no-minheight">
			<p>Could not create the new user - a user with that email address already exists.</p>
			<div className="modal-buttons">
				<Button type={ButtonType.Primary} text="Ok" onClick={() => setFetchError(false)} />
			</div>
		</div>
	);

	return (
		<>
			<DataFetcher fetchData={fetchData} setData={handleData} setError={handleError} />
			{
				confirmation.action
					? (<ConfirmationContent {...confirmation} handleConfirm={handleConfirm} deviceGroup={deletedGroup} />)
					: fetchError
						? (FetchErrorContent)
						: (UserEditorModal)
			}
		</>
	);
};

export default UserEditor;
